import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SlicesContainer from "./slices"
import Newsletter from "../components/homepage/newsletter"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
import SEO from "../components/seo"
import Ad from "../components/ad"
import { seoKeywords } from "../utils/helpers";

function AdvanceDirectiveTemplate({ pageContext, path }) {
  const content = pageContext?.data
  const adContent = content?.ad?.document?.data

  return (
    <Layout>
      <SEO
        keywords={[seoKeywords(content?.seo_keywords?.text)]}
        title={content?.seo_title?.text}
        description={content?.seo_description?.text}
        excludeSiteMetadataTitle
      />

      <SlicesContainer content={content?.body} pathName={path} />

      {adContent && <Ad content={adContent} />}
      <Newsletter location="Advance Directive Forms Newsletter" />
    </Layout>
  )
}

AdvanceDirectiveTemplate.propTypes = {
  pageContext: PropTypes.object,
  path: PropTypes.string,
}

export default withPrismicPreview(AdvanceDirectiveTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])

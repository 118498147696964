import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Button from "../components/button"
import { navigate } from "gatsby"
import { isBrowser } from "../utils/browser"

function Ad({ content }) {
  const bg_color = content?.section_bg_color?.text
    ? content?.section_bg_color?.text
    : "white"

  return (
    <section className={`${bg_color} py-16 px-6 md:py-24 md:px-32`}>
      <div className="max-w-body mx-auto">
        <div className="xl:px-0">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 my-auto">
              <img
                className="object-contain mx-auto w-4/5 mt-8 md:mt-0"
                src={content?.image?.url}
                aria-hidden
                alt={content?.image?.alt}
              />
            </div>
            <div className="md:w-1/2 my-auto px-6 md:px-0 pb-6">
              <div className="section-title-text flex-grow mb-6">
                {parse(content?.title?.html)}
              </div>
              <div className="body-text mb-4">{parse(content?.body?.html)}</div>
              <Button
                buttonText={content?.button_text?.text}
                buttonType="btn out-of-app -primary mb-2 mr-4"
                handleOnClick={(event) => {
                  event.preventDefault()
                  if (content?.button_link?.target) {
                    isBrowser() && window?.open(content?.button_link?.url)
                  } else {
                    navigate(content?.button_link?.url)
                  }
                }}
                additionalClasses="ml-0"
                eventName={{
                  eventDomain: "Out Of App",
                  eventLocation: "Ad Section",
                  eventAction: "Click",
                  eventActionName: "Learn More",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Ad.propTypes = {
  content: PropTypes.object.isRequired,
}

export default Ad
